import { Dispatch } from "redux"
import { ActionType, IngredientsInterface, CookAction, InstructionsInterface } from "../interface"



export const setIngredients = (payload: Array<IngredientsInterface>) => {
    return (dispatch: Dispatch<CookAction>) => {
        dispatch({
            type: ActionType.SET_INGREDIENTS,
            payload
        })
    }
}
export const setInstructions = (payload: Array<InstructionsInterface>) => {
    return (dispatch: Dispatch<CookAction>) => {
        dispatch({
            type: ActionType.SET_INSTRUCTIONS,
            payload
        })
    }
}
export const toggleIngredients = (payload: string) => {
    return (dispatch: Dispatch<CookAction>) => {
        dispatch({
            type: ActionType.TOGGLE_INGREDIENTS,
            payload
        })
    }
}
export const toggleInstructions = (payload: string) => {
    return (dispatch: Dispatch<CookAction>) => {
        dispatch({
            type: ActionType.TOGGLE_INSTRUCTIONS,
            payload
        })
    }
}