import { FC, useEffect, useRef, useState } from "react"


export const ScrollTop = () =>{

    const x = window.scrollY === 0;
    const [isShow, setIsShow] = useState<boolean>(x);
    const [isShown, setIsShown] = useState(x);

    useEffect(() => {
        window.addEventListener("scroll", function(){
            if(window.scrollY === 0){
                // console.log('TOP')
                setIsShow(false);
            } else {
                // console.log('Not Top')
                setIsShow(true);
            }
        });
        
        if(!isShow){
            setTimeout(() => {
                setIsShown(false)
            }, 200);
        }else{
            setIsShown(true)
        }

        return () => {
            window.removeEventListener("scroll", () => {})
        }

    }, [isShow]);
    

    const handleClick =()=> {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    // console.log(window.scrollY);

    // window.addEventListener("")
    
    

    return (<>
        {isShown && 
            <div className={`scroller-top flex-row-center-center ${isShow ? "show" : "hidden"}`} onClick={handleClick}>
                <svg 
                    aria-hidden="true" 
                    focusable="false" 
                    className="svg" 
                    role="img" 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 320 320">
                        <path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path>
                    </svg>
            </div>
        } 
    </>)
}