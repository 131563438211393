


import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-light-7 pt-3 pb-3 mt-5 text-white">
        <div className="container flex-row-center-center">
            &copy; 2022 Noel Santillan
        </div>
    </footer>
  );
}

export default Footer;
