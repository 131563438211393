import { combineReducers } from "redux";
import recipeReducer from './recipeReducer'
import cookReducer from './cookReducer'
import defaultReducer from './defaultReducer'
import calendarReducer from './calendarReducer'

const reducers = combineReducers({
    recipe: recipeReducer,
    cook: cookReducer,
    default: defaultReducer,
    calendar: calendarReducer
})

export default reducers


export type RootState = ReturnType<typeof reducers>