import { RecipeAction, RecipeInterface, ActionType } from '../interface'


const initState : RecipeInterface[] = [
    {
        id : "bagnet",
        title : "Crispy Bagnet",
        details : "When we think of crunchy, savory dishes, what may often come to mind are those of the breaded variety. May it be Fried Chicken, Shrimp Tempura or other recipes of the like, we truly enjoy a good, crispy breading. But being Pinoy also means you can probably recognize the merits of a pork belly dish that’s cooked right. Some of the most beloved Filipino dishes are those of the crunchy variety, made with the deliciously meaty component. Crispy bagnet is one of the best examples of this. " ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/Crispy-Bagnet-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "3 lbs. pork belly",
                    "2 tablespoons white vinegar",
                    "2 tablespoons salt",
                    "2 bunches lemongrass",
                    "1 tablespoon whole peppercorn",
                    "1 onion",
                    "8 cups water",
                    "6 cloves garlic",
                    "3 laurel leaves",
                    "6 cups cooking oil"
                ]
            }
        ],
        tags: ['crackling', 'lechon kawali', 'pork crackling'],
        instructions: [
            "Preheat oven to 175F.",
            "Pour water into a pressure cooker. Add lemongrass, salt, whole peppercorn, and onion. Let boil.",
            "Add pork belly. Boil for 3 minutes. Cover the pressure cooker. Cook for 15 minutes.",
            "Let the pressure out completely Put the pork belly on a clean plate and let it cool-down for 10 minutes. Season the meat part with salt and ground black pepper. Turn it upside down and season the skin part with salt.",
            "Wrap the sides of the pork with an aluminum foil and then rub vinegar on the skin part.",
            "Bake it for 2 ½ hours to dehydrate the skin.",
            "Remove the pork from the oven. Set aside.",
            "Heat oil in a wide wok. Once the oil gets hot, add garlic and laurel leaves. Cook until the garlic floats. Remove the garlic and leaves afterwards using a strainer.",
            "Arrange a slab of pork belly over a skimmer. Pour hot oil over it using a ladle. Do this step until a crackling texture is formed on the skin. Perform the same steps until all the pork are done.",
            "Arrange on a serving plate. Serve with your favorite dipping sauce. Share and enjoy!",
        ]
    },{
        id : "cocoshrimp",
        title : "Coconut Shrimp",
        details : "Regardless of what country or city you’re in, a great casual dining restaurant should always have a good shrimp appetizer. This isn’t a hard condition to fulfill — considering that there are so many out there! When you cook it perfectly, shrimp can come in all sorts of recipes, appealing to all sorts of crowds. But there’s no exaggeration when I say this: this coconut shrimp can appeal to practically anyone! Something about a nice, flaky, nutty texture covering the mildly sweet flavors of shrimp really makes your mouth water. Even better is when it’s as delicious as it looks! " ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/How-to-Cook-Coconut-Shrimp-Appetizer.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "1 lb. large shrimp cleaned and shell removed",
                    "1 cup bread crumbs",
                    "1 cup sweetened desiccated coconut",
                    "Salt and ground black pepper to taste",
                    "½ cup all-purpose flour",
                    "1 cup cooking oil",
                ]
            },{
                title: "Batter ingredients:",
                data: [
                    "1 egg",
                    "1 cup all-purpose flour",
                    "1 cup fresh milk",
                    "1 teaspoon baking powder",
                    "½ teaspoon salt",
                ]
            },{
                title: "Sweet chili sauce ingredients",
                data: [
                    "2 teaspoons minced garlic",
                    "¾ cup rice vinegar",
                    "¾ cup sugar",
                    "1 piece Thai chili pepper",
                    "¾ cup water",
                    "1 teaspoon ketchup",
                    "2 teaspoons cornstarch",
                    "Salt to taste",
                ]
            }
        ],
        tags: ["appetizer", "shrimp recipes"],
        instructions: [
            "Make the Sweet chili sauce by combining Good Life Minced Garlic, Thai chili pepper, and water in a food processor or blender. Blitz for 10 seconds. Set aside.",
            "Pour vinegar in a saucepan. Let boil.",
            "Pour the garlic, chili, and water mixture. Stir and cook for 2 minutes.",
            "Add sugar. Stir until sugar dilutes completely. Season with salt and add ketchup.",
            "Combine cornstarch and 1 ½ tablespoons of water. Stir until a slurry is formed. Pour the slurry into the saucepan. Stir right away. Continue cooking in medium heat while stirring until the sauce thickens to your desired consistency. Set the sauce aside.",
            "Prepare the shrimp by seasoning with salt and ground black pepper. Let it stay for 5 minutes.",
            "Make the batter by combining all batter ingredients in a bowl. Stir until the mixture smoothens. Set aside.",
            "Combine Good Life Bread Crumbs and desiccated coconut in a bowl. Mix well. Set aside.",
            "Dredge shrimp in flour one piece at a time. Dip it in the batter afterwards. Let the excess batter drip. Roll it next in bread crumb and desiccated coconut mixture. Arrange the coated shrimp on a clean plate. Note: Make sure that both sides get coated completely. Perform this step until all the shrimp are coated.",
            "Heat oil in a pan. Fry the coconut shrimp by batch. You can flip the shrimp multiple times while frying until both sides turn golden brown in color.",
            "Place the coconut shrimp on a plate lined with a paper towel after frying.",
            "Arrange on a serving plate and serve with sweet chili sauce.",
            "Share and enjoy!",
        ]
    },{
        id : "carbonara",
        title : "Creamy Pasta Carbonara",
        details : "Everybody has their own preferences when it comes to pasta. You could lean towards the thicker kind or the thinner one, or perhaps hold more love towards red sauce over the white variety. But a recipe that people can often agree on is Pasta Carbonara. We have a plethora of delicious tomato-based pasta dishes, but many also definitely love the milder, but also rich taste of this recipe’s egg-based sauce. Its largely savory, and often less overpowering flavor has won over many people’s hearts." ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/Filipino-Style-Carbonara-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "1 lb. fettuccine",
                    "5 strips bacon chopped",
                    "68 grams Knorr Cream of Mushroom",
                    "1 cup heavy cream",
                    "2 tablespoons Parmesan cheese",
                    "4.5 ounces button mushroom sliced",
                    "2 cups water",
                    "1 tablespoon butter",
                ]
            }
        ],
        tags: ["alfredo pasta recipe", "carbonara recipe"],
        instructions: [
            "Cook fettuccine according to package instructions. Set aside.",
            "Make the creamy pasta sauce by mixing Knorr Cream of Mushroom and water. Stir until completely diluted. Set aside.",
            "Heat a pan and add chopped bacon. Cook until the bacon becomes crispy.",
            "Add butter. Let it melt and then add mushrooms. Cook for 1 minute.",
            "Pour cream of mushroom mixture into the pan. Stir and continue cooking until the sauce thickens.",
            "Add heavy cream and Parmesan cheese. Stir.",
            "Combine the cooked fettuccine with the pasta sauce. Toss until the pasta gets coated completely.",
            "Transfer to a serving plate. Serve and enjoy!",
        ]
    },{
        id : "friedchicken",
        title : "Crispy Fried Chicken",
        details : "What is comfort food to you? If you’re like me, and many other people in the world, one of the images that comes to mind is a gorgeously crispy fried chicken. Simple but flavorful, fried chicken is a treat for both kids and adults alike. Whether you’re enjoying it at a kid’s birthday party or serving it as an appetizer for game night with your barkada (group of friends), crispy fried chicken is definitely a classic choice." ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2016/12/Crispy-Fried-Chicken-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "3 lbs. chicken cut into individual pieces",
                    "1 tablespoon salt",
                    "3 cups cooking oil",
                    "1 cup all-purpose flour",
                ]
            },{
                title: "Batter Ingredients",
                data: [
                    "¾ cup warm not hot fresh or evaporated milk",
                    "1 Knorr Chicken Cube",
                    "2 eggs",
                    "¾ cups all-purpose flour",
                    "1 teaspoon baking powder",
                    "2 teaspoons garlic powder",
                    "½ teaspoon salt",
                    "¼ teaspoon ground black pepper",
                ]
            }
        ],
        tags: ["fried chicken", "cripy fry"],
        instructions: [
            "Rub salt all over the chicken. Let it stay for 15 minutes.",
            "Heat the oil in a cooking pot.",
            "Prepare the batter. Start by pressing a fork on the chicken cube until it is completely squashed. Combine it with warm milk. Stir until well blended. Set aside.",
            "Combine flour, baking powder, garlic powder, salt, and ground black pepper. Mix well using a fork or a wire whisk. Set aside.",
            "Beat the eggs in a large mixing bowl. Add the milk mixture. Continue to beat until all the ingredients are all incorporated. Add half of flour mixture. Whisk. Add the remaining half and whisk until the texture of the batter becomes smooth.",
            "Dredge the chicken in flour and then dip in batter. Roll it again in flour until completely covered. Fry in medium heat for 7 minutes per side.",
            "Remove from the pot and put in a plate lined with paper towel. This will absorb the oil.",
            "Serve with ketchup or gravy.",
            "Share and enjoy!",
        ]
    },{
        id : "tofusisig",
        title : "Sizzling Tofu Sisig",
        details : "What are your New Year’s resolutions? One of the most common aspirations people bring up every time a new year comes along is the desire to eat healthier. Health is wealth; maintaining a good and balanced diet is a surefire way to be able to enjoy all that life has to offer. But eating right doesn’t mean having to forfeit all of the delicious food you love! While it’s true that most of your favorite tasty treats tend to come with something a little indulgent, healthy food has become all the rage. Thus, more people are trying their best to eat better while still enjoying what they put in their mouth. This involves taking classic dishes and giving them a healthier spin. For instance, sisig lovers will be surprised to find that all the flavors they love in the classic dish can be found in this sizzling tofu sisig rendition!" ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/Sizzling-Tofu-Sisig-Recipe-Panlasang-Pinoy.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "19 oz firm tofu",
                    "1 red onion chopped",
                    "2 tablespoons red bell pepper chopped",
                    "2 tablespoons liver spread",
                    "½ cup green onion chopped",
                    "¼ cup cooking oil",
                ]
            },{
                title: "Dressing Ingredients",
                data: [
                    "½ cup Lady’s Choice Mayonnaise",
                    "1 Tablespoon Knorr Liquid Seasoning",
                    "½ teaspoon onion powder",
                    "1 teaspoon lemon juice",
                ]
            }
        ],
        tags: ["alfredo pasta recipe", "carbonara recipe"],
        instructions: [
            "Prepare the tofu. Squeeze the liquid out by pressing against a flat surface. Zip it inside a resealable bag and freeze overnight. Thaw the tofu and press again to remove the remaining liquid.",
            "Heat the oil in a pan. Fry the tofu until golden brown and crispy. Do the same step on the opposite side. Remove tofu from the pan and let it cool down. Chop into small pieces.",
            "Make the dressing by combining all the dressing ingredients in a bowl. Mix well. Set aside.",
            "Saute onion until it softens. Add tofu and liver spread. Cook for 1 minute.",
            "Add the bell pepper and dressing mixture. Stir until all ingredients are coated. Set aside.",
            "Heat a metal plate (sizzling plate) and add butter. Transfer the tofu sisig into the metal plate.",
            "Serve as an appetizer or a main dish. Share and enjoy!",
        ]
    },{
        id : "beefcaldereta",
        title : "Beef Caldereta",
        details : "With meat cooked until so tender it falls off the bone, it’s no secret that beef caldereta is a Filipino favorite. A mix of ingredients come together in this tomato-based stew practically bursting with flavor. The beef caldereta recipe is one you’ll find in practically any Filipino cookbook—and most Filipino homes don’t even need one! This dish is one type of food you can enjoy whether in an intimate setting or with a larger crowd. No matter where or who you enjoy this dish with, I guarantee you won’t be able to get enough of this beef caldereta recipe!" ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/Beef-Caldereta.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "4 lbs beef short ribs",
                    "1 Knorr Beef Cube",
                    "14 ounces tomato sauce",
                    "1 cup red wine",
                    "2 large potato cubed",
                    "1 large carrot cubed",
                    "1 cup manzanilla olives",
                    "2 bell pepper sliced",
                    "4 sprigs thyme",
                    "2 large onion chopped",
                    "1 head garlic chopped",
                    "3 tablespoons soy sauce",
                    "½ cup liver spread",
                    "1 cup water",
                    "¼ cup extra virgin olive oil",
                    "Salt and ground black pepper to taste",
                ]
            }
        ],
        tags: ["beef kaldereta", "beef recipes", "holiday"],
        instructions: [
            "Heat oil in a pot. Fry the carrots for 2 minutes. Make sure that all sides are cooked equally. Remove from the pot. Set aside.",
            "Fry the potato until the color of the outer part turns light brown. Remove from the pot and set aside.",
            "Using the remaining oil, sauté garlic, onion, and thyme. Continue sautéing until the onion caramelizes.",
            "Add beef short ribs. Cook until the color of the outer part turns light brown.",
            "Pour tomato sauce and red wine. Add Knorr Beef Cube.",
            "Add soy sauce and water. Cover the pot. Continue cooking the stew in low heat setting until the beef tenderizes completely.",
            "Add liver spread and bell pepper. Cook for 3 minutes.",
            "Add carrot and potato. Cook for 3 minutes.",
            "Add cheddar cheese and then season with salt and ground black pepper.",
            "Transfer to a serving plate. Serve. Share and enjoy!",
        ]
    },{
        id : "macaronisalad",
        title : "Creamy Macaroni Salad",
        details : "Easy, creamy, and bright, macaroni salad has graced many dining tables and celebrations over the years and around the world. And it’s not without good reason! This dish is light and airy — a perfect combination for many heavy, rich, and saucy dishes. At restaurants, you can often find macaroni salad as a side dish for meats like ribs, barbecue, and the like. Whether you realize it or not, a fantastic macaroni salad can make or break your dish. Luckily, this creamy macaroni salad is sure to be a hit with all your loved ones." ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/Creamy-Macaroni-Salad-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "1 lb. elbow macaroni",
                ]
            },{
                title: "Dressing Ingredients",
                data: [
                    "350 ML Lady’s Choice Mayonnaise",
                    "8 ounces ham diced",
                    "8 ounces chicken breast boiled and shredded",
                    "½ cup pineapple chunks",
                    "1 carrot minced",
                    "2 stalks celery chopped",
                    "2 Tablespoons sweet pickle relish",
                    "1 Tablespoon onion minced",
                    "½ cup cheddar cheese shredded",
                    "1 teaspoon sugar",
                    "1 Tablespoon parsley chopped",
                    "Salt and ground black pepper to taste",
                ]
            }
        ],
        tags: ["chicken macaroni salad", "mac salad"],
        instructions: [
            "Cook the macaroni based on package instructions. Set aside.",
            "Make the dressing by combining all the dressing ingredients in a large mixing bowl. Mix well.",
            "Add the cooked macaroni. Toss until the macaroni is coated with the dressing.",
            "Chill by placing the bowl inside the refrigerator for at least 1 hour.",
            "Serve and enjoy with your family.",
        ]
    },{
        id : "panfrynoodlewithbeefandveggies",
        title : "Pan Fried Noodles with Beef and Veggies",
        details : "A dish that is definitely hard to say no to is a deliciously filling plate of Pan Fried Noodles. It’s the kind of meal that also goes with a great variety of toppings and spices. It can work like a perfect blank canvas for most recipes with its satisfying texture and somewhat neutral flavor. But a lot of Filipinos love to have it in this very classic way— with an abundance of leafy vegetables and the most tasty slices of beef. " ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2022/01/Pan-Fried-Noodles-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "14 ounces egg noodles",
                    "½ lb. beef sliced into thin pieces",
                    "¼ cup green onion chopped",
                    "3 bunches bok choy",
                    "½ cup carrots Julienne",
                    "1 onion sliced",
                    "2 cloves garlic minced",
                    "1 teaspoon minced ginger",
                    "5 tablespoons cooking oil",
                ]
            },{
                title: "Sauce ingredients",
                data: [
                    "3 ½ Tablespoons oyster sauce",
                    "2 teaspoons sesame oil",
                    "1 teaspoon sugar",
                    "2 Tablespoons soy sauce",
                    "3 Tablespoons Shaoxing cooking wine",
                    "1 ½ cups water",
                    "3 teaspoons cornstarch",
                    "1/8 teaspoon ground white pepper",
                ]
            }
        ],
        tags: ["fried noodles", "pancit canton"],
        instructions: [
            "Prepare the noodles based on package instructions. Discard remaining water.",
            "Heat 2 tablespoons of cooking oil in a pan. Add the noodles. Fry until the bottom part turns light brown. Continue by frying the opposite side (Note: if necessary, do this in 2 batches)",
            "Remove the noodles from the pan and arrange on a serving dish.",
            "Combine all the sauce ingredients in a bowl. Mix well. Set aside.",
            "Heat remaining oil on the same pan. Stir fry the beef until it browns. Add onion, ginger, and garlic. Cook until onion softens.",
            "Add green onion and bok choy. Cook for 1 minute.",
            "Pour the sauce mixture into the pan. Stir. Continue cooking until the sauce thickens to your desired consistency.",
            "Pour everything over the fried noodles. Serve hot.",
            "Share and enjoy!",
        ]
    },{
        id : "callos",
        title : "Callos",
        details : "If there’s one thing you can count on in Filipino cuisine, it’s that we know what we’re doing with our meat. Innovative and unafraid in our cooking, Filipinos see the endless possibilities in any ingredient, and are able to make it into a dish nothing short of fantastic. It is one of our strongest points, and one of the things that is unquestionable about our culture: we know how to make really, really good food. So it comes as no surprise, then, that this callos recipe is, frankly, fantastic." ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2010/02/Callos-1.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "1 1/2 lb ox feet veal shanks, cleaned",
                    "2 lbs ox tripe cleaned",
                    "15 ounces chick peas garbanzo beans",
                    "8 ounces tomato sauce",
                    "2 chorizo de bilbao sliced",
                    "1/4 lb bacon sliced crosswise in 1 inch length",
                    "1 bell pepper cut into thick strips",
                    "1 onions sliced",
                    "1/4 cup extra virgin olive oil EVOO",
                    "1 teaspoon salt",
                    "1/2 teaspoon ground black pepper",
                    "1 carrot cubed",
                    "1 teaspoon whole peppercorn",
                    "4 cups water",
                ]
            }
        ],
        tags: ["callos", "beef tripe"],
        instructions: [
            "Pour water in a casserole or cooking pot and bring to a boil.",
            "Put-in the onion, whole peppercorn, ox feet, and ox tripe. Simmer until the ox feet and tripe are extremely tender (you may use a slow cooker or pressure cooker for this step).",
            "Remove the ox feet and tripe from the cooking pot and let it cool down for a few minutes. Set the stock aside for later use.",
            "Cut the ox tripe into bite size pieces and debone the ox feet. Set aside.",
            "Heat a large wok or pan then pour-in the olive oil.",
            "Add chorizo de bilbao and bacon then cook in medium heat for 5 to 8 minutes.",
            "Pour-in the tomato sauce then let boil.",
            "Add the tender ox feet and tripe and 2 cups of stock (water used to boil ox feet and ox tripe) then simmer for 10 minutes.",
            "Add salt and pepper then put-in the carrots. Simmer for 3 minutes.",
            "Put-in the chick peas and bell pepper then simmer for 10 minutes.",
            "Transfer to a serving dish.",
            "Serve hot. Share and enjoy!",
        ]
    },{
        id : "stroganofflasagna",
        title : "Beef Stroganoff Lasagna",
        details : "There is something truly irresistible about two popular dishes that have come together for one recipe. From Bagnet Sisig to even the classic Ice Cream Cake, these kinds of recipes are definitely worth trying because truly— how could you say no to having the best of both worlds? If you’ve ever wondered what it would be like to have the savory, delicious Beef Stroganoff Lasagna, this recipe is for you! " ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2021/12/Beef-Stroganoff-Lasagna-Recipe-1024x683.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "1 lb beef sirloin",
                    "8 ounces lasagna",
                    "1 onion sliced",
                    "2 cups chopped mushroom",
                    "3 tablespoons butter",
                    "8 ounces mozzarella cheese",
                ]
            },{
                title: "Sauce Ingredients",
                data: [
                    "1 pack 68 grams Knorr Cream of Mushroom",
                    "2 teaspoons paprika",
                    "½ cup sour cream",
                    "2 ½ cups water",
                ]
            }
        ],
        tags: ["lasagna", "pasta", "cheesy", "beef"],
        instructions: [
            "Cook lasagna based on package instructions. Set aside.",
            "Make the sauce by melting the butter in a pan. Add beef sirloin and then cook until it turns light brown.",
            "Add onion and mushroom. Continue sauteeing for 3 minutes.",
            "Combine Knorr Cream of Mushroom with 2 ½ cups water. Stir until well blended. Pour the mixture into the pan. Stir and cook until the sauce thickens.",
            "Add paprika. Cook for another 3 minutes.",
            "Turn the heat off and then add sour cream. Stir to mix all the ingredients.",
            "Preheat the oven to 350F. Assemble the lasagna in the baking pan starting with the stroganoff followed by the lasagna. Top with mozzarella. Continue with the pattern until all ingredients are used up.",
            "Bake for 30 minutes.",
            "Remove from the oven and serve warm with garlic bread. Share and enjoy!",
        ]
    },{
        id : "buttershrimppancitcanton",
        title : "Garlic Butter Shrimp Pancit Canton",
        details : "An assortment of ingredients, a harmony of sauces, and delicious noodles to boot — there’s no dish quite like pancit canton! While sinigang and adobo typically take the coveted national dish spot, I’d say pancit is in a league of its own. This dish is the perfect balance of sweet and savory, with its medley of ingredients bringing out the best in each other. The no stress, no fuss preparation of pancit canton makes it enjoyable for people from all walks of life. But have you ever wanted to find a way to elevate this timeless dish? Enter the elegant, classy garlic butter shrimp — the perfect companion you never knew your pancit canton needed!" ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2021/12/Garlic-Butter-Shrimp-Pancit-Canton-1024x683.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "10 shrimp cleaned",
                    "1 egg fried",
                    "1 Knorr Shrimp Cube",
                    "21 ounces pancit canton flour noodles",
                    "4 cups water",
                    "2 cups cabbage shredded",
                    "½ lemon",
                    "1 head garlic minced",
                    "1 onion sliced",
                    "4 Tablespoons soy sauce",
                    "5 Tablespoons oyster sauce",
                    "1 cup green onion chopped",
                    "¼ cup butter",
                    "2 Tablespoons cooking oil",
                    "Salt and ground black pepper to taste",
                ]
            }
        ],
        tags: ["butter", "shrimp", "canton"],
        instructions: [
            "Melt butter in a wok. Add cooking oil.",
            "Put the garlic in the wok. Stir-fry until it turns light brown.",
            "Add shrimp. Cook each side for 1 minute.",
            "Squeeze the lemon over the shrimp. Cover the wok. Continue cooking for 1 minute. Season with salt.",
            "Remove the shrimp from the wok and place in a clean plate. Leave the juices in the wok.",
            "Allow the juices to evaporate until you are only left with the fats (butter and oil mixture).",
            "Sauté onion until it softens and then add soy sauce and water. Let it boil.",
            "Add Knorr Shrimp cube. Stir.",
            "Put the cabbage and carrot in the wok. Cook for 30 seconds.",
            "Add the noodles. Gently toss while cooking. Cook until the noodles completely absorb the liquid.",
            "Add oyster sauce and green onion. Season with ground black pepper. Toss until all ingredients are well distributed.",
            "Arrange the pancit canton in a serving plate and then top with shrimp and fried egg.",
            "Share. Serve and enjoy!",
        ]
    },{
        id : "porknigang",
        title : "Porknigang (Sinigang na Baboy Ribs)",
        details : "One of the most beloved and familiar Filipino dishes out there, sinigang introduces a great balance of warmth and sourness. The tamarind broth is something that brings a great uniqueness to the dish, while hyping up the flavor of all other ingredients. But have you ever imagined this culinary classic with say, tender cuts of pork baby back ribs? This might be your call to give Porknigang a try. " ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2021/12/Porknigang-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "3 lbs. pork baby back ribs",
                    "3 bunches bok choy",
                    "1 1/2 liter water",
                    "44 grams Knorr Sinigang sa Sampaloc with Gabi",
                    "15 counts string beans cut in 2-inch pieces",
                    "8 okra",
                    "1 eggplant sliced",
                    "3 long green chili pepper",
                    "1 cup daikon radish sliced",
                    "2 tomato",
                    "1 onion wedged",
                    "3 tablespoons cooking oil",
                    "Fish sauce and ground black pepper to taste",
                ]
            }
        ],
        instructions: [
            "Heat oil in a large pot. Saute onion and tomato until the layers of the onion starts to separate.",
            "Add pork spare ribs. Continue sautéing until the pork turns light brown in color.",
            "Pour water. Cover the pot. Let the water boil. Adjust heat to low setting and continue boiling for 30 minutes.",
            "Add Knorr Sinigang sa Sampaloc with Gabi recipe mix and daikon radish. Stir and cover the pot. Continue cooking until the pork gets tender.",
            "Add chili pepper, string beans, okra, and eggplant. Cook for 12 minutes.",
            "Add pechay. Cook for 3 minutes.",
            "Season with fish sauce and ground black pepper.",
            "Transfer to a serving bowl. Serve with rice. Share and enjoy!",
        ],
        tags: ["pork", "sour soup", "ribs"],
    },{
        id : "bulalo",
        title : "Bulalo",
        details : "It’s a well known fact that Filipinos love stew and soup dishes. From the sour sinigang to the sweet tinola, there’s guaranteed to be a soup dish for every flavor palate preference –– all great to sip and enjoy, especially during long, cool nights. But one of the most popular, and arguably most delicious, soups out there, is the classic Bulalo, or beef shank stew." ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2009/03/Beef-Shank-Soup-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "2 lbs beef shank",
                    "½ cabbage whole leaf individually detached",
                    "1 bundle bok choy",
                    "2 cobs corn each cut into 3 parts",
                    "2 Tablespoons whole peppercorn",
                    "1/2 cup green onions cut unto 1 1/2 inch pieces",
                    "1 onion",
                    "34 ounces water",
                    "fish sauce to taste optional",
                ]
            }
        ],
        instructions: [
            "In a big cooking pot, pour in water and bring to a boil",
            "Put-in the beef shank followed by the onion and whole pepper corn then simmer for 1.5 hours (30 mins if using a pressure cooker) or until meat is tender.",
            "Add the corn and simmer for another 10 minutes",
            "Add the fish sauce,cabbage, pechay, and green onion (onion leeks)",
            "Serve hot. Share and Enjoy!",
        ],
        tags: ["bulalo", "beef bones", "stew"],
    },{
        id : "beefpareslomi",
        title : "Beef Pares Lomi",
        details : "Comfort food — everyone has one. Whatever dish came to your mind just now is what can soothe and satisfy your soul during the toughest of days. A good comfort food is perfect no matter the weather or situation, and can spark joy whether you’re getting it from home or buying it at your favorite restaurant. During the cool seasons, few things are more satisfying than a homey, delicious plate of your favorite meal. For some, this can come in the tender, juicy beef pares, flavorful in every bite. But others may seek solace instead in dishes like lomi, whose thick and tasty broth can put a smile on any face. Both dishes are amazing in their own right — but do you know what would make them even better? Combining them! Hence this beef pares lomi, which takes the best of both dishes and creates something extraordinary!" ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2021/12/Beef-Pares-Lomi-Recipe.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "2 1/2 lbs beef kalitiran",
                    "1 Knorr Beef Cube",
                    "1 lb. fresh miki noodles",
                    "5 cloves garlic chopped",
                    "1 onion chopped",
                    "1 1/2 teaspoons ginger chopped",
                    "2 tablespoons potato starch",
                    "3 tablespoons brown sugar",
                    "2 star anise",
                    "1 egg",
                    "3 tablespoons soy sauce",
                    "6 cups water",
                    "3 tablespoons cooking oil",
                    "Salt and ground black pepper to taste",
                ]
            }
        ],
        instructions: [
            "Heat oil in a wok. Sauté garlic, onion, and ginger until the onion softens.",
            "Add beef. Sauté until the beef turns light brown.",
            "Pour the soy sauce and water. Cover and Let boil.",
            "Add star anise, sugar, and Knorr Beef Cube. Stir. Cover the wok and then adjust the heat to a low setting. Continue cooking until the beef tenderizes",
            "Remove and discard the star anise. Add the fresh noodles and then season with salt and ground black pepper. Cook for 3 minutes.",
            "Thicken the soup by adding a slurry. Do this by combining potato starch with 3 tablespoons of water. Pour it into the wok and add beaten egg. Stir. Cook until the soup thickens based on your desired consistency.",
            "Serve with soy dipping sauce and garlic fried rice, if preferred. Share and enjoy!",
        ],
        tags: ["beef", "soup", "pares"],
    },{
        id : "minilechonbellyroll",
        title : "Mini Lechon Belly Roll",
        details : "Exceptionally delicious and juicy, there is nothing quite like a warm, fresh plate of Crispy Mini Lechon Belly Roll. This may be a dish you’re used to ordering from outside for special occasions. But wouldn’t it be great to know how to make it from scratch? After all, we never run short of Filipino celebrations where a gorgeous belly roll wouldn’t fit perfectly on the dining table." ,
        img : "https://panlasangpinoy.com/wp-content/uploads/2021/12/Crispy-Mini-Lechon-Belly-Roll.jpg",
        ingredients: [
            {
                title: "Ingredients",
                data: [
                    "4 lbs. pork belly",
                    "1 onion sliced",
                    "1 head garlic minced",
                    "1 bunch lemongrass",
                    "1 bunch green onion",
                    "3 tablespoons salt",
                    "1 1/2 tablespoons ground black pepper",
                    "3 tablespoons cooking oil",
                ]
            }
        ],
        instructions: [
            "Slice the pork belly in half. Lay it flat on a clean surface and then rub salt and ground black pepper on the side with meat.",
            "Spread the garlic over each piece of belly and arrange the sliced onion, lemongrass, and green onion.",
            "Roll the pork belly in a way that all ingredients remain inside. Make sure that both ends are touching. Securely tie the belly using a kitchen twine.",
            "Arrange the two pork belly rolls in a steamer. Steam for 45 minutes.",
            "Remove from the steamer and let it cool down for 15 minutes. Rub oil all over each belly roll.",
            "Arrange the belly rolls in a turbo broiler and then broil for 45 minutes.",
            "Slice and serve while still hot. Share and enjoy!",
        ],
        tags: ["pork", "crispy", "lechon"],
    }
]

const reducer = (state: Array<RecipeInterface> = initState, action: RecipeAction): Array<RecipeInterface> => {
    console.log(state)
    switch (action.type){
        case ActionType.ADD_RECIPE:
            return [...state, action.payload];
        default:
            return state
    }
}

export default reducer