// import { configureStore } from '@reduxjs/toolkit'
// import recipeReducer from './recipeSlice'
// import cookReducer from './cookSlice'
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk"
import reducers from "./reducers/index";

// const store = configureStore({
//     reducer: {
//       recipe: recipeReducer,
//       cook: cookReducer
//     }
// })
export const store = createStore(
    reducers,
    {},
    applyMiddleware(thunk)
)
  
  // Infer the `RootState` and `AppDispatch` types from the store itself
  // export type RootState = ReturnType<typeof store.getState>
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  // export type AppDispatch = typeof store.dispatch

  export default store
