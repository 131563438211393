

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../redux';
import { useLocation } from 'react-router-dom'

function Navbar() {

  const _default = useSelector( (state: RootState) => state.default )
  const location = useLocation()
  

  // console.log('_default', _default, location.pathname.trim().split('/'))

  return (
    <div className="navbar">
        <div className="container">
          <Link to="/" className="site-title text-primary cur-pointer">PP Recipe's</Link>
          <div className="">
              {_default.currentCook !== "" && location.pathname.split('/')[1] !== "cook" &&  <Link to={`/cook/${_default.currentCook}`} className="font-md ml-2 nav">Continue Cooking</Link>}
              <Link to="/about" className="font-md ml-2 nav">About</Link>
              <Link to="/recipes" className="font-md ml-2 nav">Recipes</Link>
              <Link to="/planner" className="font-md ml-2 nav">Planner</Link>
          </div>
        </div>
    </div>
  );
}

export default Navbar;
