import { Dispatch } from "redux"
import { RecipeInterface, RecipeAction, ActionType } from "../interface"

export const addRecipe = (payload: RecipeInterface) => {
    return (dispatch: Dispatch<RecipeAction>) => {
        dispatch({
            type: ActionType.ADD_RECIPE,
            payload
        })
    }
}


