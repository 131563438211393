import { useParams } from "react-router";
import { useEffect, useState } from "react";

import { FaChevronRight, FaCheck } from 'react-icons/fa'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions, RootState } from "../redux/index";
import { rngPassword } from "../helper";

interface IngredientsData {
    id: string,
    text: string,
    status: boolean
}

interface Ingredients {
    title: string,
    data: Array<IngredientsData>
}
interface Instructions {
    id: string,
    text: string,
    status: boolean
}

export const Cook = () => {
    const { recipeid } = useParams();

    // const { recipe } = useAppSelector(state => state.recipe)
    // const { ingredients, instructions } = useAppSelector(state => state.cook)
    
    const dispatch = useDispatch()
    const { setIngredients, setInstructions, toggleIngredients, toggleInstructions, setCurrentCook, setIsIngredientPrepDone } = bindActionCreators(Actions, dispatch)


    const { cook, recipe, default: _default } = useSelector( (state: RootState) => state )
    const { ingredients, instructions } = cook


    const recipeData = recipe.find(rec => rec.id === recipeid) !



    const newIngredientData: Array<Ingredients> = recipeData.ingredients.map(ing => {
        return {
            title: ing.title,
            data: ing.data.map(d => { 
                return {
                    id: rngPassword(),
                    text: d,
                    status: false
                }
            })
        }
    })
    const newInstructionsData: Array<Instructions> = recipeData.instructions.map(ins => {
        return {
            id: rngPassword(),
            text: ins,
            status: false
        }
    })

    

    const [isAllIngredientsChecked, setIsAllIngredientsChecked] = useState<boolean>(false);
    const [isIngredientsPrepDone, setIsIngredientsPrepDone] = useState<boolean>(_default.isIngredientPrepDone);
    const [isAllInstructionsDone, setIsAllInstructionsDone] = useState<boolean>(false);
    
    const toggleIngredientData = (id: string) => {
        toggleIngredients(id)
    }
    const toggleInstructionsData = (id: string) => {
        toggleInstructions(id)
    }
    
    const isIngredientsChecked = () : boolean => {
        let gate = true;
        ingredients.forEach(ing => {
            ing.data.forEach(d => {
                if(!d.status){
                    gate= false
                }
            })
        })
        return gate;
    }
    const isInstructionsChecked = () : boolean => {
        let gate = true;
        instructions.forEach(ins => {
            if(!ins.status){
                gate= false
            }
        })
        return gate;
    }

    const checkIngredients = ()=>{
        if(isIngredientsChecked()){
            // console.log('All Checked');
            setIsAllIngredientsChecked(true)
        }else{
            setIsAllIngredientsChecked(false)
        }
    }
    const checkInstructions = ()=>{
        if(isInstructionsChecked()){
            //   console.log('All Checked');
            setIsAllInstructionsDone(true)
        }else{
            setIsAllInstructionsDone(false)
        }
    }

    useEffect(() => {
        if(ingredients.length === 0 && instructions.length === 0){
            setIngredients(newIngredientData)
            setInstructions(newInstructionsData)
        }

        checkIngredients()
        checkInstructions()

    }, []);
    

    useEffect(() => {
        checkIngredients()
    }, [ingredients]);
    
    useEffect(() => {
        checkInstructions()
    }, [instructions]);

    const handleSetIngredientPrepClick =(state: boolean)=>{
        // setIsIngredientsPrepDone(state)
        setIsIngredientsPrepDone(state)
        setIsIngredientPrepDone(state)
    }

  return (
      <div className="container">
            <h1 className="font-xxl mt-3 mb-2 text-primary">{recipeData.title}</h1>
            {!isIngredientsPrepDone && <>
                <h2>Step 1: Ingredients Preparation</h2>
                <p className="text-gray mt-1">Check the ingredients that are prepared. Once all are prepared we are ready for cooking.</p>
                {ingredients.map((ing, index) => {
                    return (
                        <div key={`${ing.title}-${index}`} className="container mt-3 flex-col-start-start">
                            <span className="font-lg">{ing.title}</span>
                            <ul className="pl-2 flex-col-start-start text-lh-2 ">
                                {ing.data.map((d, index1) => 
                                    <li key={`${ing.title}-${index}-${index1}`} className="mt-1">
                                        <label className="flex-row-center-start">
                                            {d.status && <input className="mr-2" onChange={()=> toggleIngredientData(d.id)} type="checkbox" checked/> }
                                            {!d.status && <input className="mr-2" onChange={()=> toggleIngredientData(d.id)} type="checkbox" /> }
                                            <div>{d.text}</div>
                                        </label>
                                    </li> 
                                )}
                            </ul>
                        </div>
                    )
                })}
                <div className="container flex-row-center-center">
                    {!isAllIngredientsChecked && <span className="btn mt-4 font-md text-space-2" >Preparing Ingredients...</span>}
                    {isAllIngredientsChecked && <span className="btn mt-4 font-md text-space-2" onClick={()=> handleSetIngredientPrepClick(true)}>All Ingredients ready. Begin Cooking</span>}
                </div>
            </>}
            {isIngredientsPrepDone && <>
                <h2>Step 2: Cooking</h2>
                <p className="text-gray mt-1">Check the Instructions that are done.</p>
                <div className="container mt-3 flex-col-start-start">
                    <ul className="pl-2 flex-col-start-start text-lh-2 ">
                        {instructions.map((ins, index) => {
                            return (
                                <li key={ins.id} className="w-100 mb-1">
                                    <div onClick={()=> toggleInstructionsData(ins.id)} className={`cur-pointer text-align-start p-1 bg-whitesmoke flex-row-center-between bg-hover-gray-light-5 ${ins.status && "o-50"}`}>
                                        <span className="w-90">{ins.text}</span>
                                        {!ins.status && <FaChevronRight className="w-5 h-2 text-primary" />}
                                        {ins.status && <FaCheck className="w-5 h-2 text-primary" />}
                                    </div>
                                </li> 
                            )
                        })}
                    </ul>
                </div>
                <div className="container flex-row-center-center">
                    {isAllInstructionsDone && <Link to="/" onClick={()=> setCurrentCook("")} className="btn mt-3 font-md text-space-2">Finito</Link>}
                    {!isAllInstructionsDone && <span className="btn mt-3 font-md text-space-2">Cooking...</span>}
                </div>
                
            </>}
         
      </div>
  );
}