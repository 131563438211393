import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CalendarWidget from "../components/CalendarWidget";
import { PlannerWidget } from "../components/PlannerWidget";
import { RootState } from "../redux";
import { CalendarInterface } from "../redux/interface";



export interface CD {
    id: string,
    recipeid: string,
}
export interface CData {
    br: CD | null,
    lu: CD | null,
    di: CD | null
}
export interface CalData {
    date: Date,
    data: CData
}

export const Planner: FC = () => {

    const calendarData = useSelector( (state: RootState) => state.calendar )

    // console.log('calendarData', calendarData);
    
  
    function getISOWeek(simple: Date) {

        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        const temp = {
          d: ISOweekStart.getDate(),
          m: ISOweekStart.getMonth(),
          y: ISOweekStart.getFullYear(),
        }
        // console.log(ISOweekStart)
        const numDaysInMonth = new Date(temp.y, temp.m + 1, 0).getDate()
        
        return Array.from({length: 7}, _ => {
          if (temp.d > numDaysInMonth){
            temp.m +=1;
            temp.d = 1;
            // not needed, Date(2020, 12, 1) == Date(2021, 0, 1)
            /*if (temp.m >= 12){
              temp.m = 0
              temp.y +=1
            }*/
          }
          
          return new Date(temp.y, temp.m, temp.d++)
        });
    }

    // const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const imgurl = 'https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg'


    const dateToText = (_date: Date) =>{
        // must use getCorrectDateFormat date passed in here
        const date = new Date(_date);
        const d = date.getDate();
        const m = date.getMonth() + 1;
        const year = date.getFullYear();
    
        // console.log(d, m, year)
    
        let day = '';
        let month = '';
    
        if(d <= 9){
            day = '0' + d;
        }else{
            day = d.toString();
        }
    
        if(m <= 9){
            month = '0' + m;
        }else{
            month = m.toString();
        }
        
        return `${month}-${day}-${year}`
    }
    const getCorrectDateFormat = ( data : {day: number, month: number, year: number} ) =>{
        return new Date(data.year, data.month - 1, data.day)
    }

    const getCalendarData =(date: Date)=>{
        let ret : Array<CalendarInterface> = []
        // console.log(calendarData)
        calendarData.forEach(cal => {
            // console.log(cal.date, date, cal.date.getTime() === date.getTime())
            if(cal.date.getTime() === date.getTime()){
                ret.push(cal)
            }
        })
        return ret
    }
    const getMonthObject = (date: Date) : Array<Array<CalData>> =>{
        const fd = new Date(date.getFullYear(), date.getMonth(), 1);
        const ld = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let ret = [];
        const lmld = new Date(date.getFullYear(), date.getMonth(), 0);
        const lmzd = new Date(date.getFullYear(), date.getMonth(), 0)
        lmzd.setDate((lmzd.getDate() - lmzd.getDay()));
        
        const add = lmld.getDate() - lmzd.getDate() + 1;
    
        // return `AAAAA, ${fd} ${ld} ${lmld} ${lmzd} `
    
        let x = fd.getDate()
        let y = ld.getDate() + add
    
        while (x < y){
            let week: Array<CalData> = []; 
            // const current = new Date(date.getFullYear(), date.getMonth(), x)
            const current = new Date(date.getFullYear(), date.getMonth(), x)
            current.setDate((current.getDate() - current.getDay()));
            for (var i = 0; i < 7; i++) {
                const calData = getCalendarData(new Date(current))

                let data : CData = {
                    br: null,
                    lu: null,
                    di: null
                }
                calData.forEach(cd => {
                    if(cd.meal === "br"){
                        data.br = {
                            id: cd.id,
                            recipeid: cd.recipeid,
                        }
                    }
                    if(cd.meal === "lu"){
                        data.lu = {
                            id: cd.id,
                            recipeid: cd.recipeid,
                        }
                    }
                    if(cd.meal === "di"){
                        data.di = {
                            id: cd.id,
                            recipeid: cd.recipeid,
                        }
                    }
                })
                week.push(
                    // dateToText( new Date(current) )
                    {
                        date: new Date(current),
                        data
                    }
                );
                current.setDate(current.getDate() +1);
                // if(ld.getMonth === current.getMonth()){
                    x++;
                // }
            }
            ret.push(week)
        }
    
        // console.log(ret)
        return ret;
    
    }

    const [monthObj, setMonthObj] = useState <Array<Array<CalData>>> (getMonthObject(new Date())) 
    const [currentMonth, setCurrentMonth] = useState <number> (new Date().getMonth() + 1);
    const [currentWeek, setCurrentWeek] = useState <number | null> (null);
    const [weekObj, setWeekObj] = useState <Array<CalData> | null> (null);

    useEffect(() => {
        
        setMonthObj(getMonthObject(new Date(getCorrectDateFormat({day: 15, month: currentMonth, year: new Date().getFullYear()}))))

    }, [currentMonth]);

    useEffect(() => {
        
        if(currentWeek !== null){
            setWeekObj(monthObj[currentWeek])
        }else{
            setWeekObj(null)
        }
      
    }, [currentWeek]);
    

    useEffect(() => {
      
        setMonthObj(getMonthObject(new Date(getCorrectDateFormat({day: 15, month: currentMonth, year: new Date().getFullYear()}))))
        if(currentWeek !== null){
            setWeekObj(monthObj[currentWeek])
        }

    }, [calendarData]);
    
    
    const refreshWeekObj = () => {
        setWeekObj(monthObj[currentWeek!])
    }

    const nextMonth = () : boolean => {
        if(currentMonth < 12){
            setCurrentMonth(ps => ps + 1)
            return true
        }else{
            return false
        }
    }
    const prevMonth = () : boolean => {
        
        if(currentMonth > 1){
            setCurrentMonth(ps => ps - 1)
            return true
        }else{
            return false
        }
    }
    const setWeek = (w: number | null) : void => {
        setCurrentWeek(w)
    }

    


    return (<>
        <div className="container flex-col-center-start mt-5">
            <h1 className="font-xxl text-primary">Menu Planner</h1>
        </div>
        <div className="bg-primary-light-7 mt-2 mb-2">
            <div className="container flex-row-center-center p-4 font-md">
                <p>Weekly Menu Planner lets you plan out your Meals! Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, deleniti temporibus laudantium maxime quos magni assumenda cumque nobis perspiciatis numquam.</p>
            </div>
        </div>


        {currentWeek === null && <CalendarWidget mo={monthObj} currentMonth={currentMonth} nextMonth={nextMonth} prevMonth={prevMonth} setWeek={setWeek} />}
        {weekObj && <PlannerWidget weekObj={weekObj} setWeek={setWeek} refreshWeekObj={refreshWeekObj} />}
        

        


    </>)
}