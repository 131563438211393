// import { ActionType } from "./action-types"

export enum ActionType {
    ADD_RECIPE = "addrecipe",
    SET_INGREDIENTS = "setingredients",
    SET_INSTRUCTIONS = "setinstructions",
    TOGGLE_INGREDIENTS = "toggleingredients",
    TOGGLE_INSTRUCTIONS = "toggleinstructions",
    SET_CURRENT_COOK = "setcurrentcook",
    SET_INGREDIENT_ISDONE = "setisingredientdone",
    ADD_CALENDAR_DATA = "addcalendardata",
    REMOVE_CALENDAR_DATA = "removecalendardata",
}

export interface RecipeIngredients {
    title: string,
    data: string[]
}

export interface RecipeInterface {
    id: string,
    title: string,
    details: string,
    img: string,
    ingredients: Array<RecipeIngredients>,
    tags: string[],
    instructions: string[]
}


export interface IngredientsData {
    id: string,
    text: string,
    status: boolean
}

export interface IngredientsInterface {
    title: string,
    data: Array<IngredientsData>
}

export interface InstructionsInterface {
    id: string,
    text: string,
    status: boolean
}



export interface RecipeAction {
    type:  ActionType.ADD_RECIPE,
    payload: RecipeInterface
}


export interface SetIngredients {
    type:  ActionType.SET_INGREDIENTS
    payload: Array<IngredientsInterface>
}
export interface SetInstructions {
    type:  ActionType.SET_INSTRUCTIONS
    payload: Array<InstructionsInterface>
}
export interface updateIngredients {
    type:  ActionType.TOGGLE_INGREDIENTS
    payload: string
}
export interface updateInstructions {
    type:  ActionType.TOGGLE_INSTRUCTIONS 
    payload: string
}

export type CookAction = SetIngredients | SetInstructions | updateIngredients | updateInstructions

export interface CookStateInterface {
    ingredients: IngredientsInterface[],
    instructions: InstructionsInterface[]
}




export interface DefaultInterface {
    currentCook: string,
    isIngredientPrepDone: boolean
}



export interface CurrentCookAction {
    type:  ActionType.SET_CURRENT_COOK,
    payload: string
}

export interface setIsIngredientPrepDoneAction {
    type:  ActionType.SET_INGREDIENT_ISDONE,
    payload: boolean
}


export type DefaultAction = CurrentCookAction | setIsIngredientPrepDoneAction




export enum CalendarMealType {
    BREAKFAST = "br",
    LUNCH = "lu",
    DINNER = "di"
}


export interface CalendarInterface {
    id: string,
    date: Date,
    recipeid: string,
    meal: CalendarMealType.BREAKFAST | CalendarMealType.LUNCH | CalendarMealType.DINNER
}


export interface addCalendarData {
    type:  ActionType.ADD_CALENDAR_DATA 
    payload: CalendarInterface
}
export interface removeCalendarData {
    type:  ActionType.REMOVE_CALENDAR_DATA 
    payload: string
}

export type CalendarAction = addCalendarData | removeCalendarData