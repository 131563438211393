import { FC, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CalData } from '../pages/Planner'
import { RootState } from "../redux";
import { RecipeInterface } from "../redux/interface";

interface CalendarWidgetProps {
    mo: Array<Array<CalData>>,
    currentMonth: number,
    prevMonth: ()=> boolean,
    nextMonth: ()=> boolean,
    setWeek : (w: number | null) => void
}
 
const CalendarWidget: FC<CalendarWidgetProps> = ({ mo, currentMonth, prevMonth, nextMonth, setWeek }) => {
    // const imgurl = 'https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg'
    const recipe = useSelector( (state: RootState) => state.recipe )


    // console.log('mo', mo)

    function getRecipe ( id: string ) : RecipeInterface {
        return recipe.find(rec => rec.id === id)!
    }

    function getMonthText(month: number){
        switch (month) {
            case 1:
                return "January"
            case 2:
                    return "February"
            case 3:
                    return "March"
            case 4:
                    return "April"
            case 5:
                    return "May"
            case 6:
                    return "June"
            case 7:
                    return "July"
            case 8:
                    return "August"
            case 9:
                    return "September"
            case 10:
                    return "October"
            case 11:
                    return "November"
            case 12:
                    return "December"
            default:
                break;
        }
    }

    
    return ( 
        <div className="calendar-widget mb-3 flex-col-start-start">
            <div className="cal-con header container flex-row-center-even p-1">
                {/* <h1> a </h1> */}
                {currentMonth > 1 ? <FaChevronLeft onClick={()=> prevMonth()} /> : <FaChevronLeft style={{color: "grey"}} /> }
                <div className="p-1 w-40 bg-whitesmoke br-sm flex-col-center-center">
                    <h1 className="p-0">{getMonthText(currentMonth)}</h1>
                    <p className="font-sm">{new Date().getFullYear()}</p>
                </div>
                {currentMonth < 12 ? <FaChevronRight onClick={()=> nextMonth()} /> : <FaChevronRight style={{color: "grey"}} />}
            </div>
            <div className="header container flex-row-center-between">
                <div className="box flex-row-center-center">
                    <h3>Sun</h3>
                </div>
                <div className="box flex-row-center-center">
                    <h3>Mon</h3>
                </div>
                <div className="box flex-row-center-center">
                    <h3>Tue</h3>
                </div>
                <div className="box flex-row-center-center">
                    <h3>Wed</h3>
                </div>
                <div className="box flex-row-center-center">
                    <h3>Thu</h3>
                </div>
                <div className="box flex-row-center-center">
                    <h3>Fri</h3>
                </div>
                <div className="box flex-row-center-center">
                    <h3>Sat</h3>
                </div>
            </div>
            {mo.map((mobj, mi) => {
                return <div key={`mobj-${mi}`} onClick={()=> setWeek(mi)} className="cal-con container flex-row-center-between">
                    {mobj.map(monthobj => {
                        const today = new Date()
                        const selday = new Date(monthobj.date)
                        today.setHours(0,0,0,0)
                        selday.setHours(0,0,0,0)
                        // console.log(today.getTime(), selday.getTime(), today.getTime() === selday.getTime())
                        

                        return <div key={monthobj.date.toDateString()} className={`box trans-1 font-sm p-1 ${today.getTime() === selday.getTime() && "today"  } ${monthobj.date.getMonth() + 1 !== currentMonth && "inactive"}`}>
                            <p>{monthobj.date.getDate()}</p>
                            <div className="menu flex-row-center-between">
                                { monthobj.data.br !== null ? <img src={getRecipe(monthobj.data.br.recipeid).img} title={"Breakfast: " + getRecipe(monthobj.data.br.recipeid).title} alt="" /> : <div ></div>}
                                { monthobj.data.lu !== null ? <img src={getRecipe(monthobj.data.lu.recipeid).img} title={"Lunch: " + getRecipe(monthobj.data.lu.recipeid).title} alt="" /> : <div ></div>}
                                { monthobj.data.di !== null ? <img src={getRecipe(monthobj.data.di.recipeid).img} title={"Dinner: " + getRecipe( monthobj.data.di.recipeid).title} alt="" /> : <div ></div>}
                            </div>
                        </div>
                    })}
                </div>
            })}
           
        </div>
    );
}
 
export default CalendarWidget;