import RecipeWidget from "../components/RecipeWidget";
import { useSelector } from 'react-redux'
import { RootState } from "../redux";
import { useState } from "react";
import { searchRecipe } from "../helper";


function Recipes() {

    // const { recipe } = useAppSelector(state => state.recipe)
    
    const _recipe = useSelector( (state: RootState ) => state.recipe )

    const [recipe, setRecipe] = useState(_recipe);
    
    const handleSearchChange = (q: string) =>{
        // console.log(q)
        setRecipe(
            searchRecipe(_recipe, q)
        )
        
    }

  return (
      <div className="container flex-col-center-start mt-5">
          
        <input className="p-1 input mb-2 font-md" onChange={(e) => handleSearchChange( e.target.value )} type="text" placeholder="Search Recipes"  />

        {recipe.map(rec => 
            <RecipeWidget key={rec.id} data={rec} />
        )}
        {/* <RecipeWidget 
            title="Adobo" 
            details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente ipsum perspiciatis veniam illo a! At pariatur omnis ducimus asperiores aspernatur." 
            id="adobo123"
            img="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg"
        />

        <RecipeWidget 
            title="Adobo" 
            details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente ipsum perspiciatis veniam illo a! At pariatur omnis ducimus asperiores aspernatur." 
            id="adobo123"
            img="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg"
        />
        <RecipeWidget 
            title="Adobo" 
            details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente ipsum perspiciatis veniam illo a! At pariatur omnis ducimus asperiores aspernatur." 
            id="adobo123"
            img="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg"
        />
        <RecipeWidget 
            title="Adobo" 
            details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente ipsum perspiciatis veniam illo a! At pariatur omnis ducimus asperiores aspernatur." 
            id="adobo123"
            img="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg"
        />
        <RecipeWidget 
            title="Adobo" 
            details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente ipsum perspiciatis veniam illo a! At pariatur omnis ducimus asperiores aspernatur." 
            id="adobo123"
            img="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg"
        />
        <RecipeWidget 
            title="Adobo" 
            details="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente ipsum perspiciatis veniam illo a! At pariatur omnis ducimus asperiores aspernatur." 
            id="adobo123"
            img="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg"
        /> */}

      </div>
  );
}

export default Recipes;
