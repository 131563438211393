import { FC } from "react";
import { Link } from 'react-router-dom'
import { RecipeInterface } from "../redux/interface";
// import { RecipeType } from '../redux/recipeSlice'


interface Props {
    data: RecipeInterface
}


const RecipeWidget: FC<Props> = ({ data }) => {    

  return (
      <Link to={`/recipes/${data.id}`} className=" recipe-widget flex-row-start-between p-2-2 col-12-sm ">
            <div className="details flex-col-start-start">
                <h1>{data.title}</h1>
                <p className="text-align-start text-gray">{data.details}</p>
            </div>
            <div className="imgcon">
                <img className="trans-scale-hover-3 trans-2" src={data.img} alt="" />
            </div>
      </Link>
  );
}

export default RecipeWidget;


