import { FC, useEffect, useRef, useState } from "react";
import {  FaChevronLeft, FaTimes, FaUtensils } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rngPassword, searchRecipe } from "../helper";
import { CalData, CD} from '../pages/Planner'
import { Actions, RootState } from "../redux";
import { CalendarMealType, RecipeInterface } from "../redux/interface";

interface Props {
    weekObj: Array<CalData> | null,
    setWeek: (w: number | null) => void,
    refreshWeekObj: () => void
}
export interface PlannerWidgetPopupProps {
    weekdata: CalData | null, 
    data: CD | null,
    selectedRecipeTitle: string,
    handleRecipeTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void | null,
    showPopup: boolean,
    closePopup: () => void
}
 
export const PlannerWidget: FC<Props> = ({ weekObj, setWeek, refreshWeekObj }) => {

    // const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const imgurl = 'https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg'
    const dispatch = useDispatch()
    const { addCalendarData } = bindActionCreators(Actions, dispatch)

    const recipe = useSelector( (state: RootState) => state.recipe )
    function getRecipe ( id: string ) : RecipeInterface {
        return recipe.find(rec => rec.id === id)!
    }
    const modalRef = useRef (null)


    const [selectedRecipe, setSelectedRecipe] = useState <RecipeInterface | null> (null);
    const [selectedRecipeTitle, setSelectedRecipeTitle] = useState<string>("");
    const [selectedMeal, setSelectedMeal] = useState<string>("");

    

    const [showPopup, setShowPopup] = useState(false);
    const [weekdata, setWeekdata] = useState<CalData | null>(null);

    const [searchResults, setSearchResults] = useState<Array<RecipeInterface>>(recipe);
    
    
    const widgetBox = (data: CD | null, weekdata: CalData, meal: string) : JSX.Element  => {
        return <div onClick={ () => handleBoxClick( weekdata, data, meal ) } className="item col-3-sm flex-row-center-center ">
            <div className={`wrapper bg-whitesmoke flex-row-center-center ${data !== null && "active"}`}>
                {data !== null && <img className="trans-1" src={getRecipe(data.recipeid).img} alt="" />}
                <div className="cover trans-1"></div>
                <h3 className="title p-1 trans-2 ">{data !== null ? getRecipe(data.recipeid).title : <FaUtensils />}</h3>
            </div>
        </div>
    }
    const widgetBoxes = (data: CalData) : JSX.Element => {
        return (<>
            {widgetBox(data.data.br, data, "Breakfast")}
            {widgetBox(data.data.lu, data, "Lunch")}
            {widgetBox(data.data.di, data, "Dinner")}
        </>)
    }


    const handleBoxClick = (weekdata: CalData | null, data: CD | null, meal: string) => {
        setShowPopup(true)
        setWeekdata(weekdata)
        setSelectedRecipe(data ? getRecipe(data.recipeid) : null)
        setSelectedMeal(meal)
    }
    const handleRecipeTitleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value.length);
        setSelectedRecipeTitle(e.target.value)
        setSearchResults(searchRecipe(recipe, e.target.value))
    }
    const closeModal = () =>{
        setShowPopup(false)
        refreshWeekObj()
    }
    const handleClick =(e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
        if(e.target !== modalRef.current){
            return
        }else{
            closeModal();
        }
    }

    

    const handleChangeRecipeClick = (recipe : RecipeInterface, date: Date) => {
        setSelectedRecipe(recipe)

        function getMeal() : CalendarMealType {
            switch (selectedMeal) {
                case "Breakfast":
                    return CalendarMealType.BREAKFAST
                case "Lunch":
                    return CalendarMealType.LUNCH
                case "Dinner":
                    return CalendarMealType.DINNER
                default:
                    return CalendarMealType.BREAKFAST
            }
        }
        
        addCalendarData({
            id: rngPassword(),
            date,
            recipeid: recipe.id,
            meal: getMeal()
        })

    }


    useEffect(() => {
      if(showPopup){
        document.body.style.overflow = "hidden";
      }else{
        document.body.style.overflow = "auto";
      }
    }, [showPopup]);
    

    return ( <>
        {showPopup &&  
            <div onClick={handleClick} className="modal" ref={modalRef}>
                <div className="modal-container">
                    <FaTimes onClick={closeModal} className="modal-close" />
                        <div className="container flex-col-center-start">
                            <div className="planner-widget-popup flex-col-center-start">
                                <h3 className="">{weekdata && weekdata.date.toDateString().split(' ').slice(0, 3).join(' ')} <span className="font-md text-primary">{selectedMeal}</span></h3>
                                
                                {selectedRecipe !== null && <div className="flex-row-center-between w-100 mt-1 p-1 bg-whitesmoke br-sm">
                                    <h3>{selectedRecipe.title}</h3>
                                    <span onClick={() => setSelectedRecipe(null)} className="btn">Change</span>
                                </div>}
                                {selectedRecipe === null && 
                                    <div className="field mt-2">
                                        <input type="text" onChange={handleRecipeTitleChange} value={selectedRecipeTitle} placeholder="Search Recipes to Assign" />
                                    </div>
                                }
                                
                                {selectedRecipe !== null && 
                                    <div className="img-con p-1">
                                        <img className="trans-1" src={selectedRecipe.img} alt="" />
                                    </div>
                                }
                                {selectedRecipe === null && weekdata &&
                                    <div className="row w-100 gap-1 mt-1">
                                        {searchResults.map(res => {
                                            return (
                                                <div key={res.id} onClick={() => handleChangeRecipeClick(res, weekdata.date) } className="option col-12-sm col-6-md col-4-lg flex-row-center-center">
                                                    <span>{res.title}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                            </div>
                        </div>
                </div>
            </div>
        }

        <div className="container flex-row-center-end mb-2">
            <button onClick={()=> setWeek(null)} className="btn p-1-4"> <FaChevronLeft className="mr-1" /> Back to Calendar</button>
        </div>
        <div className="planner-widget row flex-col-start-start">
            {weekObj!.map(week => {
                return <div key={week.date.toDateString()} className="planner-widget-item container flex-row-center-center">
                    <div className="header col-3-sm flex-col-center-center">
                        {/* <h2>{getDayText(week.date.getDay())}</h2> */}
                        <h2>{week.date.toDateString().split(' ')[0]}</h2>
                        <p className="font-sm">{week.date.toDateString().split(' ').slice(1).slice(0, 2).join(' ')}</p>
                    </div>
                    {widgetBoxes(week)}

                    
                </div>
            })}
            
            
        </div>
    </>)
}
