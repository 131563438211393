import { ActionType, CalendarInterface, CalendarAction, CalendarMealType } from '../interface'


const initState : Array<CalendarInterface> = [
    {
        id: "1",
        date: new Date(2022, 1 - 1, 1 ),
        recipeid: "bagnet",
        meal: CalendarMealType.BREAKFAST
    },{
        id: "2",
        date: new Date(2022, 1 - 1, 1 ),
        recipeid: "bagnet",
        meal: CalendarMealType.LUNCH
    },{
        id: "3",
        date: new Date(2022, 1 - 1, 1 ),
        recipeid: "cocoshrimp",
        meal: CalendarMealType.DINNER
    },{
        id: "4",
        date: new Date(2022, 1 - 1, 2 ),
        recipeid: "bagnet",
        meal: CalendarMealType.BREAKFAST
    },{
        id: "5",
        date: new Date(2022, 1 - 1, 2 ),
        recipeid: "cocoshrimp",
        meal: CalendarMealType.LUNCH
    },{
        id: "6",
        date: new Date(2022, 1 - 1, 2 ),
        recipeid: "cocoshrimp",
        meal: CalendarMealType.DINNER
    }
]

const reducer = (
    state:  Array<CalendarInterface> = initState,
    action: CalendarAction
) : Array<CalendarInterface> => {
    console.log(state)
    switch (action.type){
        case ActionType.ADD_CALENDAR_DATA:
            console.log('AAAAAAAAAAA', [...state, action.payload])
            return [...state, action.payload];
        case ActionType.REMOVE_CALENDAR_DATA:
            let x : Array<CalendarInterface> = []
            state.forEach(st => {
                if(st.id !== action.payload){
                    x.push(st)
                }
            })
            return x
        default:
            return state
    }
}


export default reducer