


export default function About() {
  return <>
    
    <div className="flex-col-center-start mt-3">
        <h1 className="font-xxl text-primary">About</h1>
    </div>
    <div className="p-3 bg-red-light-7 mt-3">
        <div className="container">
            <p className="text-white">PP Recipes is a Recipe Planner Webapp. The primary function is to plan out your daily meals. Other function includes listing of recipes and viewing plans in a calendar UI. This website is a personal Project of Noel Santillan.</p>
        </div>
    </div>

    <div className="flex-col-center-start mt-5">
        <h1 className="font-xl text-primary">Disclaimer</h1>
    </div>
    <div className="p-3 bg-primary mt-1">
        <div className="container">
            <p className="text-white">
                All of the recipe's and images included and shown in this website is under the ownership of &nbsp;
                <a className="text-black" href="https://panlasangpinoy.com/">panlasangpinoy.com</a>
            </p>
            <p className="text-white mt-1">
            Noel Santillan is using the contents solely for the purpose of personal projects. No monetization or any kind of profit is involved.
            </p>
            <p className="text-white mt-1">
                If you own the website 
                <a className="text-black p-1" href="https://panlasangpinoy.com/">panlasangpinoy.com</a>
                and have concerns or issues, please contact Noel Santillan directly 
                <a className="text-black p-1" href="mailto:noel@stlln.com">noel@stlln.com</a>
            </p>
        </div>
    </div>

  </>;
}
