import { RecipeInterface } from "../redux/interface";


export const rngPassword = (range : number = 15) : string => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for( var i=0; i < range; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}

export const searchRecipe = (_recipe: Array<RecipeInterface>, q: string) : Array<RecipeInterface> => {
    return _recipe.filter(rec => {
        // console.log(rec)
        let gate = false
        if(rec.details.toLowerCase().includes(q.toLowerCase())){
            gate = true
        }
        if(rec.title.toLowerCase().includes(q.toLowerCase())){
            gate = true
        }
        if(rec.tags.includes(q.toLowerCase())){
            gate = true
        }
        if(gate){
            return rec
        }else{
            return null
        }
    })
}