import { Link } from "react-router-dom";
// import { useAppSelector } from '../redux/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from "redux";
import { Actions } from "../redux";
import { RootState } from '../redux'


function Dashboard() {
    const dispatch = useDispatch()
    // const { addRecipe } = bindActionCreators(Actions, dispatch)


    const recipe = useSelector( (state: RootState) => state.recipe )

    // console.log(recipe)
  return (<>

        {/* <!-- intro --> */}
        <div className="container mt-5">
            <div className="flex-row-center-even">
                <div className="col-12-sm col-5-md flex-col-start-start">
                    <h2>
                        <div className="font-xxl text-primary">PP Recipes</div>
                    </h2>
                    <div className="text-gray mt-2 mb-3 text-align-start">Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
                    <a href="#work" className="btn-outlined-secondary text-secondary text-hover-white">View Recipes</a>
                </div>
                <div className="col-12-sm col-5-md">
                    <img src="https://media1.popsugar-assets.com/files/thumbor/y6J0CUjqyolOs01cp80K54g8NUQ/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/235a616304838fc2_creatv-eight-RryFk4n-vOs-unsplash/i/Modern-Kitchen-Zoom-Background.jpg" alt="" />
                </div>
            </div>
        </div>


        <section id="about" className="bg-primary-light-7 mt-5 pt-4 pb-4 text-white">
            <div className="container">
                <h2 className="mb-2 text-space-1">About My Recipes</h2>
                <p className="text-lh-3 ">Thank you for visiting us today. Please feel free to browse our collection of Panlasang Pinoy Filipino Recipes, as well as other easy delicious recipes that you can easily prepare in the comfort of your own kitchen. While we focus on Pinoy Recipes or Filipino Recipes, we also try our best to showcase recipes all around the globe. Aside from our detailed step-by-step recipes, we also provide online instructional cooking videos for free. These resources will help you learn how to cook your favorite dishes easier and with full confidence; you will feel like attending a one-on-one cooking lesson with an instructor.</p>
            </div>
        </section>

        

        <section id="work" className="mt-5">
            <div className="container">
            
            <h2 className="mb-2">Featured Recipes</h2>
            <div className="row gap-2">
                {recipe.slice(0,3).map(rec => {
                    return (
                        <Link to={`/recipes/${rec.id}`} key={rec.id} className="col-12-sm col-6-md col-4-lg">
                            <div className="dashboard-card p-1">
                                <h3 className="title m-1 text-straight">
                                {rec.title}
                                </h3>
                                <div className="body">
                                    <img src={rec.img} alt="" />
                                    <p className="m-1 ">{rec.details}</p>
                                </div>
                            </div>
                        </Link>
                    )
                })}
                
            </div>
            <div className="flex-row-center-center mt-2">
                <Link to="/recipes" className="btn-secondary text-white font-md">View All</Link>
            </div>
            </div>
        </section>
        
    </>);
}

export default Dashboard;
