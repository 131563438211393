import './css/index.css';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Footer from './components/Footer';
import Recipes from './pages/Recipes';
import Recipe from './pages/Recipe';
import { Cook } from './pages/Cook';
import { Planner } from './pages/Planner';
import About from './pages/About';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={ <Dashboard /> } />
            <Route path="/recipes" element={ <Recipes /> } />
            <Route path="/recipes/:recipeid" element={ <Recipe /> } />
            <Route path="/cook/:recipeid" element={ <Cook /> } />
            <Route path="/planner/" element={ <Planner /> } />
            <Route path="/about/" element={ <About /> } />
            {/* <Route path="/recipes/" element={ <Recipes /> } >
              <Route path="/recipes/:recipeid" element={<Recipe /> } />
            </Route> */}
            {/* <Route path="/nearby" element={<Nearby />} />
            <Route path="/contact" element={ <Contact />} />
            <Route path="/login" element={ user !== null ? <Navigate to="/" /> : <Login /> } />
            <Route path="/signup" element={ user !== null ? <Navigate to="/" /> : <Signup />  } /> */}

            {/* <Route path="/account" element={ user !== null ? <Account /> :  <Navigate to="/login" /> } />
            <Route path="/business" element={ user !== null ? <Business /> :  <Navigate to="/login" /> } />
            <Route path="/chikkas" element={ user !== null ? <Chikkas /> :  <Navigate to="/login" /> } />
            <Route path="/business/:businessid" element={ user !== null ? <BusinessManage /> :  <Navigate to="/login" /> } />
            <Route path="/businesscreate/:businessid" element={ user !== null ? <BusinessCreate /> :  <Navigate to="/login" /> } />
            <Route path="/itemwizard/:token/" element={ user !== null ? <Wizard /> :  <Navigate to="/login" /> } /> */}
            
            <Route path="*" element={ <Navigate to="/" />  } />
          </Routes>
        </BrowserRouter>
        <Footer />

    </div>
  );
}

export default App;
