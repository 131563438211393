import { ActionType, CurrentCookAction, DefaultAction, DefaultInterface } from "../interface";



const initState = {
    currentCook: "",
    isIngredientPrepDone: false
}

const reducer = (
    state: DefaultInterface = initState,
    action: DefaultAction
    ) : DefaultInterface => {
    console.log(state)
    switch (action.type){
        case ActionType.SET_CURRENT_COOK:
            return {...state, currentCook: action.payload};
        case ActionType.SET_INGREDIENT_ISDONE:
            return {...state, isIngredientPrepDone: action.payload};
        default:
            return state
    }
}


export default reducer