import { Dispatch } from "redux"
import { ActionType, CalendarInterface, CalendarAction } from "../interface"



export const addCalendarData = (payload: CalendarInterface) => {
    return (dispatch: Dispatch<CalendarAction>) => {
        dispatch({
            type: ActionType.ADD_CALENDAR_DATA,
            payload
        })
    }
}
export const removeCalendarData = (payload: string) => {
    return (dispatch: Dispatch<CalendarAction>) => {
        dispatch({
            type: ActionType.REMOVE_CALENDAR_DATA,
            payload
        })
    }
}