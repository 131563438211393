import { Dispatch } from "redux"
import { ActionType, DefaultAction } from "../interface"



export const setCurrentCook = (payload: string) => {
    return (dispatch: Dispatch<DefaultAction>) => {
        dispatch({
            type: ActionType.SET_CURRENT_COOK,
            payload
        })
    }
}
export const setIsIngredientPrepDone = (payload: boolean) => {
    return (dispatch: Dispatch<DefaultAction>) => {
        dispatch({
            type: ActionType.SET_INGREDIENT_ISDONE,
            payload
        })
    }
}