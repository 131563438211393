import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ScrollTop } from "../components/ScrollTop";
import { Actions, RootState } from "../redux";
// import { useAppSelector } from '../redux/hooks'


export const Recipe: FC = () => {
    const { recipeid } = useParams<string>();
    const recipe = useSelector( (state: RootState ) => state.recipe )

    const recipeData = recipe.find(rec => rec.id === recipeid) !
    
    const dispatch = useDispatch()
    const { setCurrentCook } = bindActionCreators(Actions, dispatch)

    const handleStartCookingClick = (id: string) => {
        setCurrentCook(id)
    }

    return (<>
        <div className="container">
            <h1 className="font-xxl mt-5 mb-2 text-primary">{recipeData.title}</h1>
        </div>
        <div className="bg-primary-light-7 text-white p-4">
            <div className="container flex-col-start-center">
                <span>{recipeData.details}</span>
                <Link to={`/cook/${recipeid}`} onClick={()=> handleStartCookingClick(recipeData.id)} className="btn mt-3 font-md ">Start Cooking</Link>
            </div>
        </div>
        <div className="row justify-center mt-3">
            <div className="col-5-lg col-8-md col-10-sm">
                <img className="" src={recipeData.img} alt="" />
            </div>
        </div>

        {recipeData.ingredients.map((ing, index) => {
            return (
                <div key={`${ing.title}-${index}`} className="container mt-3 flex-col-start-start">
                    <span className="font-lg">{ing.title}</span>
                    <ul className="pl-2 flex-col-start-start text-lh-2 ul-disc">
                        {ing.data.map((d, index1) => <li key={`${ing.title}-${index}-${index1}`} className="text-align-start mt-1 flex-row-start-start"><div>{d}</div></li> )}
                    </ul>
                </div>
            )
        })}

        <div className="container mt-3 flex-col-start-start">
            <span className="font-lg">Cooking Instructions</span>
            <ul className="pl-2 flex-col-start-start text-lh-2 ul-disc">
                {recipeData.instructions.map((d, index) => <li key={`instructions-${index}`} className="text-align-start mt-1 flex-row-start-start"> <div>{d}</div></li> )}
            </ul>
        </div>

        <div className="containter flex-row-center-center">
            <Link to={`/cook/${recipeid}`} onClick={()=> handleStartCookingClick(recipeData.id)} className="btn mt-4 font-lg p-2-5 ">Start Cooking</Link>
        </div>

        <ScrollTop />

    </>);
}

export default Recipe;
