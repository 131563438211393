import { IngredientsInterface, InstructionsInterface, CookAction, ActionType, CookStateInterface } from '../interface'

const ingredientsState : IngredientsInterface[] = []
const instructionsState : InstructionsInterface[] = []


const initState : CookStateInterface = {
    ingredients: ingredientsState,
    instructions: instructionsState
}

const reducer = (
        state: CookStateInterface = initState,
        action: CookAction
) : CookStateInterface => {
    console.log(state)
    switch (action.type){
        case ActionType.SET_INGREDIENTS:
            return { ...state, ingredients: action.payload };
        case ActionType.SET_INSTRUCTIONS:
            return { ...state, instructions: action.payload };
        case ActionType.TOGGLE_INGREDIENTS:
            const newIngredientsData = state.ingredients.map(ing => {
                return {
                    title: ing.title,
                    data: ing.data.map(d => { 
                        
                        if(d.id === action.payload){
                            return {
                                id: d.id,
                                text: d.text,
                                status: !d.status
                            }
                        }else{
                            return {...d}
                        }
                    })
                }
            })
            return { ...state, ingredients: newIngredientsData }
        case ActionType.TOGGLE_INSTRUCTIONS:
            // return { ...state, instructions: action.payload };
            const newInstructionsData = state.instructions.map(ins => {
                if(ins.id === action.payload){
                    return {
                        id: ins.id,
                        text: ins.text,
                        status: !ins.status
                    }
                }else{
                    return {...ins}
                }
            })
            return { ...state, instructions: newInstructionsData }
        default:
            return state
    }
}


export default reducer